import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ContactFormView } from '@forms/ContactFormView'
import { useExchangeStore } from '../useExchangeStore'

export type Props = Readonly<{ className?: string }>

export const ExchangeContactsView: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()

  const clientData = useExchangeStore((s) => s.clientData)

  if (!clientData) {
    // TODO: Handle it with return to previous step
    // eslint-disable-next-line no-console
    console.error('Client data must be set on VehicleForm step!')
    return null
  }

  return <ContactFormView title={t('global.forms.contactInfo.contacts')} data={clientData} className={className} />
}
