import * as React from 'react'
import { WizardConfirmationSection } from '@controls/Wizard/WizardConfirmationSection'
import { useExchangeStore } from './useExchangeStore'

export const ExchangeSummary: React.FC = () => {
  const clientData = useExchangeStore((s) => s.clientData)
  const vehicleId = useExchangeStore((s) => s.carprofVehicleId)
  const vehicleData = useExchangeStore((s) => s.vehicleData)
  const finalize = useExchangeStore((s) => s.finalize)
  const clear = useExchangeStore((s) => s.clear)

  React.useEffect(() => {
    finalize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WizardConfirmationSection
      vehicleId={vehicleId}
      clientData={clientData}
      vehicleData={vehicleData}
      topic="exchange"
      onReset={clear}
    />
  )
}
