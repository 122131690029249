import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Handler, hasValue, isEmptyString, OptionalType } from '@digital-magic/ts-common-utils'
import { displayDateFormat } from '@constants/configuration'
import { FormType } from '@api/endpoints/forms'
import { VehicleMntSearchResponse } from '@api/endpoints/vehicles/types'
import { CommonFormProps } from '@controls/types'
import { renderMenuItems } from '@controls/utils'
import { FormTextField, useZodForm } from '@controls/Form'
import { useFuelTypeMenuOptions, useTransmissionTypeOptions } from '@controls/Menu'
import { defaultImageDropzoneOptions } from '@controls/file'
import { FormDatePicker } from '@controls/Form/FormDatePicker'
import { FormFileUploadManager } from '@controls/Form/FormFileUploadManager'
import { useFormTrackedWrapper } from '@hooks/Tracking'
import { FlexContainer } from '@layout/FlexContainer'
import { BuyoutCommissionFormSchema, BuyoutCommissionFormValues } from '@forms/types'
import { useSnackbarActions } from '@hooks/useSnackbarActions'
import { SubmitErrorHandler } from 'react-hook-form'

export type SuggestVehicleFormProps = Readonly<{
  formType: FormType
  wizardStep: number
  setRegNumber: Handler<string>
  manualMode?: boolean
  formData: OptionalType<VehicleMntSearchResponse>
}> &
  CommonFormProps<BuyoutCommissionFormValues>

export type SuggestVehicleFormHandle = Readonly<{
  submitForm: () => void
}>

const mapValue = <T, R>(v: T | undefined, f: (v: T) => R): R | undefined => (hasValue(v) ? f(v) : undefined)

const SuggestVehicleFormComponent: React.ForwardRefRenderFunction<SuggestVehicleFormHandle, SuggestVehicleFormProps> = (
  { formType, wizardStep, setRegNumber, disabledControls, manualMode, formData, onSubmitSuccess },
  ref
) => {
  const { t } = useTranslation()
  const { enqueueErrorMsg } = useSnackbarActions()

  const fuelTypeOptions = useFuelTypeMenuOptions()
  const transmissionTypeOptions = useTransmissionTypeOptions()

  const form = useZodForm({
    schema: BuyoutCommissionFormSchema,
    defaultValues: {
      nextInspectionDate: null,
      imageFiles: []
    }
  })

  const internalDisabled = disabledControls || fuelTypeOptions.isFetching || transmissionTypeOptions.isFetching

  React.useEffect(() => {
    if (formData) {
      form.reset({
        registrationNumber: formData.registrationNumber,
        make: formData.mark,
        model: formData.model,
        year: formData.firstRegYear,
        engineVolCm3: formData.engineVolCm3,
        engineKw: formData.engineKw,
        nextInspectionDate: mapValue(formData.nextInspectionDate, (v) => new Date(v)),
        fuelId: mapValue(formData.engine, (v) => v.value),
        transmissionId: mapValue(formData.transmission, (v) => v.value),
        imageFiles: []
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  React.useEffect(() => {
    const formDataRegNumber = formData?.registrationNumber
    if (hasValue(formDataRegNumber)) {
      setRegNumber(formDataRegNumber)
    } else {
      setRegNumber(form.watch('registrationNumber'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualMode, formData])

  const onSubmitError: SubmitErrorHandler<BuyoutCommissionFormValues> = React.useCallback(
    (error) => {
      // TODO Fix it, must be solved with form validation
      if (isEmptyString(formData?.registrationNumber ?? form.watch('registrationNumber'))) {
        const regNumberTitle = t('pages.exchange.reg_number')
        const errorMsg = error.registrationNumber?.message
        enqueueErrorMsg(`${regNumberTitle}: ${errorMsg}`)
      }
    },
    [enqueueErrorMsg, form, formData?.registrationNumber, t]
  )

  const formSubmitHandler = useFormTrackedWrapper(
    { formName: formType, formStep: wizardStep },
    form,
    onSubmitSuccess,
    onSubmitError
  )

  React.useImperativeHandle(ref, () => ({
    submitForm: formSubmitHandler.handleSubmit
  }))

  return (
    <form onSubmit={formSubmitHandler.handleSubmit}>
      {manualMode || formData ? (
        <FlexContainer vertical>
          <FlexContainer>
            <FormTextField
              disabled={internalDisabled}
              control={form.control}
              type="text"
              name="make"
              label={t('pages.exchange.form.fields.make')}
            />
            <FormTextField
              disabled={internalDisabled}
              control={form.control}
              type="text"
              name="model"
              label={t('pages.exchange.form.fields.model')}
            />
            <FormTextField
              disabled={internalDisabled}
              control={form.control}
              type="text"
              name="year"
              label={t('pages.exchange.form.fields.year')}
              inputProps={{ inputMode: 'numeric' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 0) {
                  form.setValue('year', Number(e.target.value.slice(0, 4)))
                }
              }}
            />
          </FlexContainer>
          <FlexContainer>
            <FormTextField
              disabled={internalDisabled}
              control={form.control}
              type="number"
              name="engineVolCm3"
              label={t('pages.exchange.form.fields.displacement')}
            />
            <FormTextField
              disabled={internalDisabled}
              control={form.control}
              type="number"
              name="engineKw"
              label={t('pages.exchange.form.fields.power')}
            />
            <FormTextField
              select
              disabled={internalDisabled}
              control={form.control}
              type="text"
              name="fuelId"
              label={t('pages.exchange.form.fields.fuelType')}
            >
              {renderMenuItems(fuelTypeOptions.data ?? [])}
            </FormTextField>
          </FlexContainer>
          <FlexContainer>
            <FormTextField
              select
              disabled={internalDisabled}
              control={form.control}
              type="text"
              name="transmissionId"
              label={t('pages.exchange.form.fields.gearboxType')}
            >
              {renderMenuItems(transmissionTypeOptions.data ?? [])}
            </FormTextField>
            <FormDatePicker
              control={form.control}
              name="nextInspectionDate"
              label={t('pages.exchange.form.fields.tech_inspection')}
              format={displayDateFormat}
              disabled={internalDisabled}
            />
            <FormTextField
              disabled={internalDisabled}
              control={form.control}
              type="number"
              name="mileage"
              label={t('pages.exchange.form.fields.mileage')}
            />
          </FlexContainer>
          <FlexContainer>
            <FormTextField
              disabled={internalDisabled}
              control={form.control}
              type="number"
              name="requestedPrice"
              label={t('pages.exchange.form.fields.desired_price')}
            />
            <FormTextField
              disabled={internalDisabled}
              control={form.control}
              name="location"
              label={t('pages.exchange.form.fields.location')}
            />
          </FlexContainer>
          <FormFileUploadManager
            control={form.control}
            name="imageFiles"
            multiple={true}
            disabled={internalDisabled}
            maxFiles={10}
            options={{
              inputText: t('global.fileUpload.uploadPhoto'),
              inputTextDragActive: t('global.fileUpload.dropToUpload'),
              dropzoneOptions: defaultImageDropzoneOptions
            }}
          />
        </FlexContainer>
      ) : null}
    </form>
  )
}

export const SuggestVehicleForm = React.forwardRef(SuggestVehicleFormComponent)
