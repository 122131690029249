import * as React from 'react'
import { FormTypeSchema } from '@api/endpoints/forms'
import { SuggestVehicleForm, SuggestVehicleFormHandle } from '@forms/SuggestVehicleForm'
import { useExchangeStore } from '../useExchangeStore'
import { ExchangeApplicationFormProps } from './types'

const ExchangeApplicationFormComponent: React.ForwardRefRenderFunction<
  SuggestVehicleFormHandle,
  Omit<ExchangeApplicationFormProps, 'vehicleId'>
> = ({ formData, onSubmitSuccess, disabledControls }, ref) => {
  const setClientVehicleRegistrationNumber = useExchangeStore((s) => s.setClientVehicleRegistrationNumber)
  const activeStep = useExchangeStore((s) => s.activeStep)
  const manualMode = useExchangeStore((s) => s.manualMode)

  return (
    <SuggestVehicleForm
      formType={FormTypeSchema.enum.TRADE_IN}
      wizardStep={activeStep}
      ref={ref}
      setRegNumber={setClientVehicleRegistrationNumber}
      disabledControls={disabledControls}
      manualMode={manualMode}
      formData={formData}
      onSubmitSuccess={onSubmitSuccess}
    />
  )
}

export const ExchangeApplicationForm = React.forwardRef(ExchangeApplicationFormComponent)
