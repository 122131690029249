import * as React from 'react'
import { Trans } from 'react-i18next'
import { CommonControlProps } from '@controls/types'
import { VehicleRegisterSearchForm } from '@forms/VehicleRegisterSearchForm'
import { useExchangeStore } from '../useExchangeStore'
//import Link from '@mui/material/Link'

type Props = Readonly<{
  title?: string
}> &
  CommonControlProps

export const ExchangeFindVehicle: React.FC<Props> = ({ title, disabledControls }) => {
  //const setManualMode = useExchangeStore((s) => s.setManualMode)
  const clientVehicleRegistrationNumber = useExchangeStore((s) => s.clientVehicleRegistrationNumber)
  const setClientVehicleRegistrationNumber = useExchangeStore((s) => s.setClientVehicleRegistrationNumber)

  return (
    <VehicleRegisterSearchForm
      title={title}
      regNumber={clientVehicleRegistrationNumber}
      disabledControls={disabledControls}
      onSubmitSuccess={(data) => setClientVehicleRegistrationNumber(data.registrationNumber)}
    >
      <div>
        <Trans
          i18nKey="pages.exchange.form.findYourCar"
          /*
          i18nKey="pages.exchange.form.findYourCarDetails"

          components={{
            linkToForm: <Link href="#" onClick={() => setManualMode(true)} />
          }}
          */
        />
      </div>
    </VehicleRegisterSearchForm>
  )
}
