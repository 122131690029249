import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'
import { Page, PageContent } from '@layout/Page'
import { PageContainerWithHead } from '@layout/Page'
import { ExchangeWizard } from './ExchangeWizard'

export const ExchangePage: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const pageId = React.useId()

  const breadCrumbs = React.useMemo(
    () => [{ to: routes.Index, title: t('pages.main.title') }, { title: t('pages.exchange.title') }],
    [t, routes]
  )

  return (
    <Page id={pageId}>
      <PageContainerWithHead showCar title={t('pages.exchange.title')} breadCrumbs={breadCrumbs}>
        <PageContent subtitle={t('pages.exchange.subtitle')}>
          <ExchangeWizard containerId={pageId} />
        </PageContent>
      </PageContainerWithHead>
    </Page>
  )
}
