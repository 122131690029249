import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AccordionWithTitle } from '@controls/Accordion/AccordionWithTitle'
import { SuggestVehicleFormControls, SuggestVehicleFormHandle } from '@forms/SuggestVehicleForm'
import { ExchangeApplicationFormProps, ExchangeApplicationFormWrapperProps } from './types'
import { ExchangeApplicationForm } from './ExchangeApplicationForm'
import { ExchangeSelectedVehicle } from './ExchangeSelectedVehicle'
import { ExchangeContactsView } from './ExchangeContactsView'
import { ExchangeFindVehicle } from './ExchangeFindVehicle'

export const ExchangeApplicationMobile: React.FC<
  ExchangeApplicationFormProps & ExchangeApplicationFormWrapperProps
> = ({ vehicleId, disabledControls, onBtnBackClick, formData, canSubmit, onSubmitSuccess, defaultValues }) => {
  const { t } = useTranslation()
  const formRef = React.useRef<SuggestVehicleFormHandle>(null)

  return (
    <>
      <AccordionWithTitle title={t('global.forms.selectedCar.title')}>
        <ExchangeSelectedVehicle vehicleId={vehicleId} />
      </AccordionWithTitle>
      <AccordionWithTitle title={t('global.forms.contactInfo.contacts')}>
        <ExchangeContactsView />
      </AccordionWithTitle>
      <AccordionWithTitle title={t('pages.exchange.form.offerYourCar')}>
        <ExchangeFindVehicle disabledControls={disabledControls} />
        <ExchangeApplicationForm
          ref={formRef}
          formData={formData}
          onSubmitSuccess={onSubmitSuccess}
          defaultValues={defaultValues}
          disabledControls={disabledControls}
        />
      </AccordionWithTitle>
      <SuggestVehicleFormControls
        disabledControls={disabledControls || !canSubmit}
        onBtnBackClick={onBtnBackClick}
        onBtnSubmitClick={() => formRef?.current?.submitForm()}
      />
    </>
  )
}
