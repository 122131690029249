import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { create } from 'zustand'
import { Handler, OptionalType } from '@digital-magic/ts-common-utils'
import { VehicleId, VehicleRegistrationNumber } from '@api/endpoints'
import { ClientPersonalData, ExchangeRequest } from '@api/endpoints/forms'
import { ExchangeStep, StepsList } from './types'

export type State = Readonly<{
  activeStep: ExchangeStep
  privacyConsent: boolean
  clientData: OptionalType<ClientPersonalData>
  vehicleData: OptionalType<ExchangeRequest>
  carprofVehicleId: OptionalType<VehicleId>
  clientVehicleRegistrationNumber: OptionalType<VehicleRegistrationNumber>
  manualMode: boolean
  completed: boolean
}>

export type Actions = Readonly<{
  setFirstStep: Handler<void>
  setNextStep: Handler<ExchangeStep>
  setPrivacyConsent: Handler<boolean>
  setPreviousStep: Handler<ExchangeStep>
  setClientData: Handler<ClientPersonalData>
  setVehicleData: Handler<ExchangeRequest>
  setCarprofVehicleId: Handler<VehicleId | undefined>
  setClientVehicleRegistrationNumber: Handler<VehicleRegistrationNumber>
  setManualMode: Handler<boolean>
  finalize: Handler<void>
  clear: Handler<void>
}>

const calcNextStep = (step: ExchangeStep): ExchangeStep =>
  step === StepsList[StepsList.length - 1] ? step : StepsList[StepsList.indexOf(step) + 1]

const calcPrevStep = (step: ExchangeStep): ExchangeStep =>
  step === StepsList[0] ? step : StepsList[StepsList.indexOf(step) - 1]

const initialState: State = {
  activeStep: StepsList[0],
  privacyConsent: false,
  clientData: undefined,
  vehicleData: undefined,
  carprofVehicleId: undefined,
  clientVehicleRegistrationNumber: undefined,
  manualMode: false,
  completed: false
}

export const useExchangeStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setFirstStep: () => set(() => ({ activeStep: StepsList[0] })),
        setNextStep: (step) => set((state) => (state.activeStep === step ? { activeStep: calcNextStep(step) } : state)),
        setPrivacyConsent: (consent) => set(() => ({ privacyConsent: consent })),
        setPreviousStep: (step) =>
          set((state) => (state.activeStep === step ? { activeStep: calcPrevStep(step) } : state)),
        setClientData: (data) => set(() => ({ clientData: data })),
        setVehicleData: (data) => set(() => ({ vehicleData: data })),
        setCarprofVehicleId: (data) => set(() => ({ carprofVehicleId: data })),
        setClientVehicleRegistrationNumber: (data) => set(() => ({ clientVehicleRegistrationNumber: data })),
        setManualMode: (data) => set(() => ({ manualMode: data })),
        finalize: () => set(() => ({ completed: true })),
        clear: () => set(() => initialState)
      }),
      {
        name: 'exchangeForm',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)
