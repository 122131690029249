import { TFunction } from 'i18next'
import { CustomerDataStep, VehicleFormStep, SummaryStep, ExchangeStep } from './types'

export const exchangeStepTranslation =
  (t: TFunction) =>
  (step: ExchangeStep): string => {
    switch (step) {
      case CustomerDataStep:
        return t('pages.exchange.chooseNewAuto')
      case VehicleFormStep:
        return t('pages.exchange.enterCarDetails')
      case SummaryStep:
        return t('pages.exchange.weSendYouCarOffer')
    }
  }
