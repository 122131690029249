import * as React from 'react'
import { ExchangeRequest } from '@api/endpoints/forms/types'
import { uploadFilesList } from '@api/endpoints/forms/utils'
import { useTheme } from '@hooks/useTheme'
import { useMediaQuery } from '@mui/material'
import { useQueryFindVehicleFromMntByRegNumber } from '@hooks/ReactQuery/vehicles/useQueryFindVehicleFromMntByRegNumber'
import { useMutationUploadFile } from '@hooks/ReactQuery/forms/useMutationUploadFile'
import { useMutationRequestExchange } from '@hooks/ReactQuery/forms/useMutationRequestExchange'
import wizardStyles from '@controls/Wizard/Wizard.module.css'
import { BuyoutCommissionFormValues } from '@forms/types'
import { emptyToUndefined, nullableDateToOptionalLocalDate } from '@forms/utils'
import { useExchangeStore } from '../useExchangeStore'
import { VehicleFormStep } from '../types'
import { ExchangeApplicationFormProps, ExchangeApplicationFormWrapperProps } from './types'
import { ExchangeApplicationDesktop } from './ExchangeApplicationDesktop'
import { ExchangeApplicationMobile } from './ExchangeApplicationMobile'
import { UploadingModal } from '@controls/modal/UploadingModal'

export const ExchangeApplication: React.FC = () => {
  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))

  const [uploadedFiles, setUploadedFiles] = React.useState(0)
  const [totalFiles, setTotalFiles] = React.useState(0)

  const vehicleId = useExchangeStore((s) => s.carprofVehicleId)

  const clientVehicleRegistrationNumber = useExchangeStore((s) => s.clientVehicleRegistrationNumber)
  const manualMode = useExchangeStore((s) => s.manualMode)
  const setVehicleData = useExchangeStore((s) => s.setVehicleData)
  const setPreviousStep = useExchangeStore((s) => s.setPreviousStep)
  const setNextStep = useExchangeStore((s) => s.setNextStep)

  const findVehicleFromMntByRegNumber = useQueryFindVehicleFromMntByRegNumber(clientVehicleRegistrationNumber)

  const uploadFile = useMutationUploadFile({})

  const createExchangeRequest = useMutationRequestExchange({
    onSuccess: () => {
      setNextStep(VehicleFormStep)
    }
  })

  const incrementUploadedFiles = (): void => setUploadedFiles((uploadedFiles) => uploadedFiles + 1)
  const uploadFiles = uploadFilesList(uploadFile.mutateAsync, incrementUploadedFiles, incrementUploadedFiles)

  const isUploadingFiles = uploadedFiles < totalFiles
  const isSubmitting = isUploadingFiles || createExchangeRequest.isPending
  const disabledControls = isSubmitting || findVehicleFromMntByRegNumber.isFetching

  if (!vehicleId) {
    // Don't show component content because this situation is only possible when page navigates away to catalog
    return null
  }

  const onBtnBackClick = (): void => {
    setPreviousStep(VehicleFormStep)
  }

  const onSubmitSuccess = async ({
    imageFiles,
    engineVolCm3,
    engineKw,
    nextInspectionDate,
    ...data
  }: BuyoutCommissionFormValues): Promise<void> => {
    //TODO: check why we use here BuyoutCommissionFormValues in Exchange, maybe better to rename it
    setUploadedFiles(0)
    setTotalFiles(imageFiles.length)
    const imageIds = await uploadFiles(imageFiles).then((files) => files.map((file) => file.fileId))
    const vehicleData: ExchangeRequest = {
      carprofCarId: vehicleId,
      imageIds,
      engineVolCm3: emptyToUndefined(engineVolCm3),
      engineKw: emptyToUndefined(engineKw),
      nextInspectionDate: nullableDateToOptionalLocalDate(nextInspectionDate),
      ...data
    }
    setVehicleData(vehicleData)
    createExchangeRequest.mutate(vehicleData)
  }

  const formProps: ExchangeApplicationFormProps & ExchangeApplicationFormWrapperProps = {
    vehicleId,
    disabledControls,
    onBtnBackClick,
    onSubmitSuccess,
    canSubmit: !disabledControls && (manualMode || findVehicleFromMntByRegNumber.isSuccess),
    formData: findVehicleFromMntByRegNumber.data
  }

  return (
    <>
      <UploadingModal open={isUploadingFiles} uploadedFiles={uploadedFiles} totalFiles={totalFiles} />
      <div className={wizardStyles.wizardContainer}>
        {isDownMd ? <ExchangeApplicationMobile {...formProps} /> : <ExchangeApplicationDesktop {...formProps} />}
      </div>
    </>
  )
}
