import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { VehicleId } from '@api/endpoints'
import { CommonControlProps } from '@controls/types'
import { useLanguage } from '@hooks/useLanguage'
import { SelectedVehicle } from '@forms/SelectedVehicle'
import { VehicleSelectionMode } from '@pages/types'
import { urlCatalogWithSelectionMode } from '@pages/utils'
import { useExchangeStore } from '../useExchangeStore'

export type ExchangeSelectedVehicleProps = Readonly<{
  vehicleId: VehicleId
}> &
  CommonControlProps

export const ExchangeSelectedVehicle: React.FC<ExchangeSelectedVehicleProps> = ({ vehicleId, disabledControls }) => {
  const navigate = useNavigate()
  const { language } = useLanguage()

  const setVehicleId = useExchangeStore((s) => s.setCarprofVehicleId)

  const handleChooseAnotherClick = (): void => {
    setVehicleId(undefined)
    navigate(urlCatalogWithSelectionMode(VehicleSelectionMode.Exchange, language))
  }

  return (
    <SelectedVehicle
      vehicleId={vehicleId}
      onChooseAnotherClick={handleChooseAnotherClick}
      disabledControls={disabledControls}
    />
  )
}
