import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { OptionalType, parseOptionalIntNanSafe, toOptionalType } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { useTheme } from '@hooks/useTheme'
import { useMediaQuery } from '@mui/material'
import { ContainerIdProps } from '@controls/types'
import { Wizard } from '@controls/Wizard'
import { CustomerDataStep, VehicleFormStep, StepsList, SummaryStep } from './types'
import { exchangeStepTranslation } from './utils'
import { useExchangeStore } from './useExchangeStore'
import { ExchangeApplication } from './ExchangeApplication'
import { ExchangeCustomer } from './ExchangeCustomer'
import { ExchangeSummary } from './ExchangeSummary'

export const ExchangeWizard: React.FC<ContainerIdProps> = ({ containerId }) => {
  const { t } = useTranslation()
  const [initialStep, setInitialStep] = React.useState<boolean>(true)

  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))
  const isDownSm = useMediaQuery(currentTheme.breakpoints.down('sm'))

  const scrollPoint = isDownSm ? 95 : isDownMd ? 290 : 320

  const [searchParams] = useSearchParams()
  const vehicleId: OptionalType<VehicleId> = parseOptionalIntNanSafe(toOptionalType(searchParams.get('vehicleId')))

  const activeStep = useExchangeStore((s) => s.activeStep)
  const completed = useExchangeStore((s) => s.completed)
  const setCarprofVehicleId = useExchangeStore((s) => s.setCarprofVehicleId)
  const setFirstStep = useExchangeStore((s) => s.setFirstStep)
  const clear = useExchangeStore((s) => s.clear)

  const stepTranslation = exchangeStepTranslation(t)

  React.useEffect(() => {
    if (vehicleId) {
      setCarprofVehicleId(vehicleId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId])

  const renderStep = (): React.ReactElement => {
    switch (activeStep) {
      case CustomerDataStep:
        return <ExchangeCustomer />
      case VehicleFormStep:
        return <ExchangeApplication />
      case SummaryStep:
        return <ExchangeSummary />
    }
  }

  React.useEffect(() => {
    // We use initialStep-state to prevent scrolling to the stepper on the very first page load
    if (initialStep) {
      setInitialStep(false)
    } else if (containerId && !initialStep) {
      document.getElementById(containerId)?.scrollTo(0, scrollPoint)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  React.useEffect(() => {
    if (activeStep !== CustomerDataStep && !vehicleId) {
      setFirstStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (initialStep && completed) {
      clear()
    }
  }, [activeStep, completed, clear, initialStep])

  return (
    <Wizard
      steps={StepsList}
      activeStep={activeStep}
      buildStepTitle={stepTranslation}
      buildStepCompleted={(step) => step < activeStep || activeStep === 3}
      renderStep={renderStep}
    />
  )
}
