import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { VehicleOfferRequest, VehicleOfferRequestSchema } from '@api/endpoints/forms/types'
import { Button } from '@mui/material'
import { CommonFormProps } from '@controls/types'
import { FormTextField, useZodForm } from '@controls/Form'
import europeanCarLabel from '@assets/images/est.png'
import styles from './VehicleRegisterSearchForm.module.css'
import formStyles from '@assets/css/forms.module.css'
import { FlexContainer } from '@layout/FlexContainer'

export type VehicleRegisterSearchFormProps = Readonly<{
  title?: string
  regNumber: string | undefined
}> &
  CommonFormProps<VehicleOfferRequest> &
  React.PropsWithChildren

export const VehicleRegisterSearchForm: React.FC<VehicleRegisterSearchFormProps> = ({
  title,
  regNumber,
  disabledControls,
  onSubmitSuccess,
  children
}) => {
  const { t } = useTranslation()

  const form = useZodForm({ schema: VehicleOfferRequestSchema })

  React.useEffect(() => {
    form.reset({ registrationNumber: regNumber })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regNumber])

  return (
    <FlexContainer vertical responsiveGap className={styles.regNumContainer}>
      {title && <h3 className={formStyles.formSubtitle}>{title}</h3>}
      {children}
      <form onSubmit={form.handleSubmit(onSubmitSuccess)}>
        <FlexContainer fixedDirection responsiveGap className={styles.regNumForm}>
          <FormTextField
            control={form.control}
            name="registrationNumber"
            type="text"
            placeholder={t('global.forms.vehicleRegisterSearch.fields.regNumber.placeholder')}
            disabled={disabledControls}
            InputProps={{
              startAdornment: <img alt="European register logo" src={europeanCarLabel} />
            }}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={disabledControls} // TODO: add Photo Loading
          >
            {t('global.forms.vehicleRegisterSearch.buttons.find')}
          </Button>
        </FlexContainer>
      </form>
    </FlexContainer>
  )
}
