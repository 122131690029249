import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SuggestVehicleFormControls, SuggestVehicleFormHandle } from '@forms/SuggestVehicleForm'
import { ExchangeApplicationFormProps, ExchangeApplicationFormWrapperProps } from './types'
import { ExchangeApplicationForm } from './ExchangeApplicationForm'
import { ExchangeSelectedVehicle } from './ExchangeSelectedVehicle'
import { ExchangeFindVehicle } from './ExchangeFindVehicle'
import { ExchangeContactsView } from './ExchangeContactsView'
import { FlexContainer } from '@layout/FlexContainer'

export const ExchangeApplicationDesktop: React.FC<
  ExchangeApplicationFormProps & ExchangeApplicationFormWrapperProps
> = ({ vehicleId, disabledControls, onBtnBackClick, formData, canSubmit, onSubmitSuccess, defaultValues }) => {
  const { t } = useTranslation()
  const formRef = React.useRef<SuggestVehicleFormHandle>(null)

  return (
    <>
      <ExchangeSelectedVehicle vehicleId={vehicleId} />
      <FlexContainer>
        <ExchangeContactsView />
        <ExchangeFindVehicle
          title={t('pages.exchange.form.carOfferedForExchange')}
          disabledControls={disabledControls}
        />
      </FlexContainer>
      <ExchangeApplicationForm
        ref={formRef}
        formData={formData}
        onSubmitSuccess={onSubmitSuccess}
        defaultValues={defaultValues}
        disabledControls={disabledControls}
      />
      <SuggestVehicleFormControls
        disabledControls={disabledControls || !canSubmit}
        onBtnBackClick={onBtnBackClick}
        onBtnSubmitClick={() => formRef?.current?.submitForm()}
      />
    </>
  )
}
