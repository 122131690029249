import * as React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { DatePickerElement, DatePickerElementProps } from 'react-hook-form-mui'
import { TextFieldProps } from '@mui/material'
import { CommonFormFieldProps } from '@controls/Form/types'
import styles from './FormDatePicker.module.css'

export type FormDatePickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  // TODO: Resolve why can't take all the properties from DatePickerElementProps (type mismatch)
> = Pick<DatePickerElementProps<TFieldValues, TName>, 'name' | 'label' | 'format' | 'disabled'> &
  CommonFormFieldProps<TFieldValues> & {
    size?: TextFieldProps['size']
  }

export const FormDatePicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  size = 'small',
  //inputProps,
  ...props
}: FormDatePickerProps<TFieldValues, TName>): React.ReactElement => {
  return (
    <DatePickerElement
      className={styles.formDataPicker}
      control={control}
      inputProps={{ size: size /*, ...inputProps*/ }}
      {...props}
    />
  )
}
